import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const DASHBOARD_API_URL = process.env.REACT_APP_DASHBOARD_URL;
const axiosInstance = axios.create({ baseURL: API_URL });
const axiosDashboardInstance = axios.create({ baseURL: DASHBOARD_API_URL });

class AuthService {


    async login(username, password) {
        const response = await axiosInstance
            .post("signin", {
                username,
                password
            });

        if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
    };

    logout() {
        localStorage.removeItem("user");


    };

    register(username, email, password) {
        return axiosInstance.post("signup", {
            username,
            email,
            password
        });
    };

    async subscribeUser(subscription,couponCode, billingAddress, billingName, currentUser) {
        return await axiosInstance.post("subscribe", {
            subscription,
            couponCode,
            billingAddress,
            billingName,
            currentUser
        });
    };
    async subscribeLifetime(paymentId, subscription, couponCode, billingAddress, billingName, currentUser, authHeader) {
        const lifetime = await axiosInstance.post("lifetimesubscribe", {
            paymentId,
            subscription,
            billingAddress,
            billingName,
            currentUser
        }, {
            headers: authHeader()
        });
        if (lifetime.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(lifetime.data));
        }
        return lifetime.data;

    };

    async upgradeUser(subscription, authHeader, user) {
        const response = await axiosInstance.post("upgrade", {
            subscription,
            user
        }, { headers: authHeader() });


        if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
    };




    async degrade(currentUser) {
        const response = await axiosInstance.post("degrade", {
            currentUser
        });

        if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;

    };

    getCurrentUser() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user;
    };



    async isSubscriber(userId, authHeader) {
        return await axiosInstance.post("issubscriber", {
            userId
        },
            { headers: authHeader() });



    };
    async getCustomerInfo(user, frontCheck) {

        const customerResponse = await axiosInstance.post("stripecustomer", {
            user, frontCheck
        });

        return customerResponse.data;
    };



    async cancelSubscription(currentUser, authHeader) {
        return await axiosInstance.post("cancelSubscription",
            { currentUser },
            {
                headers: authHeader()
            });


    };

    async resumeSubscription(currentUser, authHeader) {
        return await axiosInstance.post("resumesubscription",
            { currentUser },
            {
                headers: authHeader()
            });


    };

    async verifyUserToken(userToken, authHeader) {


        return await axiosInstance.post("verifytoken",
            {userToken},
            {
            headers:  authHeader()
        });

    };

    async UpcomingFightDataRetrieval(authHeader) {

        return await axiosDashboardInstance.get(DASHBOARD_API_URL + "private/fighters", { headers: authHeader() });
    };

    async FighterHistory(authHeader) {
        return await axiosDashboardInstance.get(DASHBOARD_API_URL + "private/fighters_history", { headers: authHeader() });


    };
    async getPublicKey() {
        return await axiosDashboardInstance.get(DASHBOARD_API_URL + "config");


    };

    async getClientSecret(subscription) {
        return await axiosDashboardInstance.post(DASHBOARD_API_URL + "create-payment-intent", {
            subscription
        });
    };

    async getClientSecretSetup(user) {
        return await axiosDashboardInstance.post(DASHBOARD_API_URL + "create-setup-intent", {

            user
        });
    };

    async changeBilling(user, billingAddress, billingName, paymentMethod) {
        return await axiosInstance.post("changebilling", {
            user,
            billingAddress,
            billingName,
            paymentMethod

        });
    };
    async resetPasswordRequest(email) {
        return await axiosDashboardInstance.post("password-recovery-request", {
            email
        });

    };

    async passwordResetAccess(newID) {
        return await axiosDashboardInstance.post("recovery-reset-verification", {
            newID
        })
    };

    async passwordReset(email, password) {
        return await axiosInstance.post("password-reset", {
            email, password
        })

    };

    async emailUnsubscribe(email) {
        return await axiosInstance.post("email-unsubscribe", {
            email
        })

    };

}
const authService = new AuthService();
export default authService;
import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import LoadingScreen from "../../components/loading/LoadingScreen";
import "./email_unsubscribe.css";
import authService from "../../services/auth.service";
const EmailUnsubscribe = (props) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(true);
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search);
    const email = searchParam.get('email');

    useEffect(() => {


        setLoading(true);
        authService.emailUnsubscribe(email).then(data => {
            setLoading(false);
            setSuccess(true);
            props.showToastMessage(data.data.message)

            console.log(data);

        }).catch(error => {
            setLoading(false);
            setSuccess(false);
            props.showToastMessage(error.data.message);

        });


        if (email !== undefined) {
            setLoading(false);
        }


    }, [email]);

    if (loading) return (
        <LoadingScreen/>
    )

    if (!success) return (
        <div className="email_unsub-container">
            <div className="email_unsub-img">
                <img src="https://api.fightxiq.com/images/email/logo.png" alt="fightxiq logo"/>
            </div>
            <div className="email_unsub-info">
                <h1>Something went wrong!</h1>
                <h2>Please try again or contact us this problem persists!</h2>

            </div>
        </div>
    )
    return (
        <div className="email_unsub-container">
            <div>
                <img className="email_unsub-img"
                    src="https://api.fightxiq.com/images/email/logo.png" alt="fightxiq logo"/>
            </div>
            <div className="email_unsub-info">
                <h1>Success!</h1>
                <h2>We have successfully removed your email address from our database.</h2>
                <p>We truly appreciate your interest in our product! Before you go, here is a 50% off coupon code for your first recurring purchase:</p>
                <h3>2G1WCqUd</h3>
                <a href="https://fightxiq.com/payment" className="email_unsub-redeem">Redeem here</a>
            </div>
        </div>
    );
};

export default EmailUnsubscribe;